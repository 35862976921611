'use client'
import { CompareProvider } from '@/contexts/compare-context'
import { getLatLngByIP } from '@/lib/utils'
import Head from 'next/head'
import { type ReactNode, useEffect } from 'react'
import { AcceptCookies } from './_components/accept-cookies'
import Footer from './_components/footer'
import Header from './_components/header'
import { useLocationStore } from '@/store/location'
import { getCookie } from '@/lib/cookies'
import { useSessionStore } from '@/store/user'
// import { useLocation } from '@/components/hook/use-location'

type Props = {
  children: ReactNode
}
export default function Layout({ children }: Props) {
  const { setLocation } = useLocationStore()
  const { setToken } = useSessionStore()

  useEffect(() => {
    const session = getCookie("session")
    setToken(JSON.parse(session ?? `{}`).token)
    const fetchCity = async () => {
      const location = await getLatLngByIP()
      if (location) {
        setLocation(location)
      }
    }
    fetchCity()
  }, [])
  return (
    <>
      <Head>
        <title>Imóveis à venda e aluguel - Sua plataforma imobiliária</title>
        <meta
          name="description"
          content="Encontre os melhores imóveis à venda e para alugar na sua região. Navegue por nosso mapa interativo e descubra as melhores oportunidades imobiliárias."
        />
        <meta
          name="keywords"
          content="imóveis, venda, aluguel, imóveis próximos, mapa interativo, oportunidades imobiliárias"
        />
        <meta
          property="og:title"
          content="Imóveis à venda e aluguel - Sua plataforma imobiliária"
        />
        <meta
          property="og:description"
          content="Navegue por nosso mapa interativo e descubra as melhores oportunidades imobiliárias."
        />
        <meta property="og:url" content={`${process.env.NEXT_PUBLIC_URL}`} />
        <script
          async
          src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7167001666985267`}
          crossOrigin="anonymous"
        ></script>
      </Head>
      <CompareProvider>
        <Header />
        <main>{children}</main>
        <Footer />
        <AcceptCookies />
      </CompareProvider>
    </>
  )
}
